import { FaRegHandPointRight } from 'react-icons/fa6'
import { ChooseUS, HomeServices, visaServices } from '../../../constants'
import React from 'react'
import { Card } from '../../../components/ui/card'
import { Slide } from 'react-awesome-reveal';

const HomeSub = () => {
    return (
        <div className='wrapper'>
            {HomeServices.map(ser => <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4 " key={ser.id}>
                <img src={ser.img} alt="ser.text" className='w-full max-md:block hidden rounded-lg' />
                <Slide direction="left" triggerOnce> {ser.id % 2 !== 0 && <img src={ser.img} alt="ser.text" className='w-full md:block hidden rounded-lg' />}</Slide>
                <div>
                    <p className='text-xl font-semibold my-1' >{ser.heading}</p>
                    {ser.text.split('\n').map((paragraph, index) => (
                        <p key={index} className='my-3 flex gap-1 mx-3 ml-6 text-justify'><span>{index + 1}.</span>{paragraph.trim()}</p>
                    ))}
                </div>
                <Slide direction="right" triggerOnce> {ser.id % 2 === 0 && <img src={ser.img} alt="ser.text" className='w-full md:block hidden rounded-lg' />}</Slide>

            </div>)}
            <div className="grid md:grid-cols-3 grid-cols-1 md:gap-10 gap-6 my-10">
                <div>
                    <h6 className='flex gap-2 items-center font-semibold'><FaRegHandPointRight color="blue" size={24}/>SKILLS, KNOWLEDGE & QUALIFICATIONS</h6>
                    <ul  className='text-justify ml-8 list-inside list-disc'>
                        <li>All Educational documents till degree</li>
                        <li>Relevant experience certificates (if any)</li>
                        <li>Passport all Pages (if any stampings)</li>
                        <li>NARIC/ENIC</li>
                        <li>PCC</li>
                    </ul>

                </div>
                <div>
                    <h6 className='flex gap-2 items-center font-semibold'><FaRegHandPointRight color="blue" size={24}/>AN ACCURATE OBSERVATION OF 100% POSITIVE RESULTS</h6>
                    <p className='text-justify ml-6'>Our collaboration with you to develop a strategy to achieve the outcomes you require is the first step. We & design a successful course of action using your perception of the situation and our knowledge of the law.</p>
                </div>
                <div>
                    <h6 className='flex gap-2 items-center text-lg font-semibold'><FaRegHandPointRight color="blue" size={24}/>GET STARTED RIGHT NOW</h6>
                    <p className='text-justify ml-7'>Be quick! get in touch with us. Let us assist you in determining the best course of action. Your chances of taking the right actions to achieve your goals are better the sooner you establish a plan of action.</p>
                </div>
            </div>
            <div className='my-6 mt-10'>
                <h5 className='text-center font-bold text-4xl'>Immigrant visas for skilled professionals</h5>
                <div className="my-6 ">
                    <h6 className='font-semibold text-xl'>Work Permits</h6>
                    <p>A legal authorization which allows a person to take employment in a country other than that in which he/she holds citizenship.</p>
                </div>
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                    {visaServices.map(vs => <Slide direction={vs.id % 2 === 0 ? "right" : "left"} key={vs.id} triggerOnce>
                    {/* {visaServices.map(vs => <Card className='flex gap-4 min-h-[160px]' key={vs.id}> */}
                            <Card className='flex gap-4 min-h-[160px]' key={vs.id}>
                            <img src={vs.img} alt="country" className='w-[200px] rounded-l-lg ' />
                            <div className="flex flex-col py-1">
                                <p className="font-semibold">{vs.heading}</p>
                                <p >{vs.text}</p>
                            </div>
                        </Card></Slide>)}
                </div>
            </div>
            <div className="my-6">
                <h5 className='text-center font-bold text-4xl mt-10 mb-6'>Why Choose Us?</h5>
                <p className='my-2'><span className="font-semibold">Expertise:</span> Our team of immigration providers has years of experience and expertise in helping clients obtain various types of visas, including VISA Switch to UK, General Skilled work visa Tier 2 VISA, Immigrant visas for skilled professionals. We stay up-to-date with the latest immigration laws and regulations to ensure that our clients receive the most accurate and reliable advice.</p>
                <p className='my-2'><span className="font-semibold">Personalized service:</span> We understand that every client's situation is unique, so we provide personalized service tailored to each individual's needs. We take the time to understand your goals and requirements, and work with you to develop a customized immigration strategy that suits your needs.</p>
                <p className='my-2'><span className="font-semibold">Smooth and hassle-free process:</span> We take care of all the paperwork and procedures involved in the immigration process, ensuring a smooth and hassle-free experience for our clients. We guide you through the entire process, from initial consultation to visa application and beyond.</p>
                <p className='my-2'><span className="font-semibold">Affordable pricing:</span> We offer competitive pricing for our services, without compromising on the quality of our work. We believe that everyone should have access to professional immigration services, so we strive to keep our fees reasonable and transparent.</p>
                <p className='my-2'><span className="font-semibold ">Responsive communication:</span> We understand the importance of timely communication and keeping our clients informed throughout the process. We are always available to answer your questions and provide updates on your case, ensuring that you are kept informed every step of the way.</p>
            </div>
        </div>
    )
}

export default HomeSub