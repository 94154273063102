import { FaArrowAltCircleRight } from 'react-icons/fa'
import { Card } from '../components/ui/card'
import React from 'react'

const HROutsourcing = () => {
  return (
    <div className=''>
    <img src="/assets/outsourcing_bg.jpg" alt="hr" className="w-full "/>
    <div className="wrapper">
    <div className='grid md:grid-cols-2 grid-cols-1 gap-10 my-10'>
    <img src="/assets/HROutsourcing.jpg" alt="256" className='max-md:block hidden '/>
      <div className='mx-4'>
        <Card className="p-2 my-4 space-y-3"><p className='font-semibold text-lg '>Cost Savings:</p>
        <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Reduces overhead costs associated with maintaining an in-house HR department.</p>
         <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Lowers expenses for HR software, training, and recruitment.</p> 
          <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Minimizes costs related to employee benefits administration.</p>
         <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Reduces the need for additional office space and resources.
         </p>
         </Card>
        <Card className="p-2 my-4  space-y-3"><p className='font-semibold text-lg'>Access to Expertise:</p>
        <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Provides access to HR professionals with specialized knowledge and skills.</p>
         <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Ensures up-to-date compliance with the latest labor laws and regulations.</p> 
          <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Offers expertise in handling complex HR issues and employee relations.</p>
         <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Brings industry best practices to the organization.</p>
         </Card>
        <Card className="p-2 my-4  space-y-3"><p className='font-semibold text-lg'>Focus on Core Business:</p>
        <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Allows management and staff to concentrate on primary business activities.</p>
         <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Frees up resources to invest in growth and innovation.</p> 
          <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Reduces time spent on HR-related administrative tasks.</p>
         <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Enables strategic planning and decision-making without HR distractions.</p>
         </Card>
        
       </div>
       <img src="/assets/HROutsourcing.jpg" alt="256" className='md:block hidden '/>
    </div>
    <div className='grid md:grid-cols-2 grid-cols-1 gap-10 my-10'>
    <img src="/assets/hro2.jpeg" alt="256"  className="w-full rounded-lg"/>
      <div className='mx-4'>
        <Card className="p-2 my-4 space-y-3 "><p className='font-semibold text-lg space-y-4'>Access to Advanced Technology:</p>
        <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Utilizes state-of-the-art HR software and systems.</p>
         <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Improves data management, reporting, and analytics capabilities.</p> 
          <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Offers employee self-service portals for easy access to HR information.</p>
         <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Enhances data security and privacy with robust HR technology solutions.</p>
         </Card>
     
        
       </div>
     
    </div>
    </div>
  
  </div>
  )
}

export default HROutsourcing