import { FaCircleArrowRight  } from "react-icons/fa6"
import { Card } from "../components/ui/card"
import React from 'react'

const HRConsulting = () => {
  return (
    <div className=''>
      <img src="/assets/hrconmain.jpg" alt="hr" className="w-full "/>
     {/*  <p className="font-bold text-4xl my-6">HR Consulting:</p> */}
      <div className="wrapper">
      <div className='grid md:grid-cols-2 grid-cols-1 gap-10 my-10'>
        <img src="/assets/hr-consulting.webp" alt="256" />
        <div className='mx-4'>
          <Card className="p-2 my-4 space-y-3"><p className='font-semibold text-lg '>Recruitment and Talent Acquisition:</p>
          <p className="flex gap-2 items-center"><FaCircleArrowRight  />Designing and implementing recruitment strategies.</p>
           <p className="flex gap-2 items-center"><FaCircleArrowRight  />Job posting and advertising.</p> 
            <p className="flex gap-2 items-center"><FaCircleArrowRight  />Screening and interviewing candidates.</p>
           <p className="flex gap-2 items-center"><FaCircleArrowRight  />Onboarding new hires.</p>
           </Card>
          <Card className="p-2 my-4 space-y-3"><p className='font-semibold text-lg '>Employee Training and Development:</p>
          <p className="flex gap-2 items-center"><FaCircleArrowRight  />Identifying training needs.</p>
           <p className="flex gap-2 items-center"><FaCircleArrowRight  />Designing training programs.</p> 
            <p className="flex gap-2 items-center"><FaCircleArrowRight  />Conducting workshops and seminars.</p>
           <p className="flex gap-2 items-center"><FaCircleArrowRight  />Evaluating training effectiveness.</p>
           </Card>
          <Card className="p-2 my-4 space-y-3"><p className='font-semibold text-lg '>Performance Management:</p>
          <p className="flex gap-2 items-center"><FaCircleArrowRight  />Developing performance appraisal systems.</p>
           <p className="flex gap-2 items-center"><FaCircleArrowRight  />Setting performance standards and goals.</p> 
            <p className="flex gap-2 items-center"><FaCircleArrowRight  />Coaching and mentoring employees.</p>
           <p className="flex gap-2 items-center"><FaCircleArrowRight  />ddressing performance issues.</p>
           </Card>
          
         </div>
      </div>
      <div className='grid md:grid-cols-2 grid-cols-1 gap-10 my-10'>
      <img src="/assets/hrc2.jpeg" alt="256"  className="w-full rounded-lg max-md:block hidden"/>
        <div className='mx-4'>
          <Card className="p-2 my-4 space-y-3"><p className='font-semibold text-lg '>Employee Relations:</p>
          <p className="flex gap-2 items-center"><FaCircleArrowRight  />Addressing workplace conflicts.</p>
           <p className="flex gap-2 items-center"><FaCircleArrowRight  />Implementing employee engagement strategies.</p> 
            <p className="flex gap-2 items-center"><FaCircleArrowRight  />Developing policies and procedures.</p>
           <p className="flex gap-2 items-center"><FaCircleArrowRight  />andling disciplinary actions and grievances.</p>
           </Card>
         </div>
         <img src="/assets/hrc2.jpeg" alt="256"  className="w-full rounded-lg md:block hidden"/>
      </div>
      </div>
    
    </div>
  )
}

export default HRConsulting