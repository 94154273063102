import { IoLogoYoutube } from "react-icons/io";
import { FaInstagram } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { Separator } from "../../ui/separator";
import { FaPinterestSquare } from "react-icons/fa";
import { Link } from "react-router-dom";
import {  userPolicy } from "../../../constants";


const Footer = () => {

  return (
    <footer className=" border-gray-500 border-t-2 xl:px-2 bg-gray-200">
      <div className="wrapper lg:justify-between lg:flex-row flex-col flex items-center gap-10">
      <p>@copyright 2024 All Rights reserved.</p>
      <div className="flex flex-col  gap-2">
        <h1 className="text-md font-bold">SOCIAL MEDIA</h1>
        <div className="flex gap-4 ">
        <IoLogoYoutube size={28} color="red"/>
        <FaInstagram size={28} color="brown"/>
        <FaFacebookF size={28} color="blue"/>
        <FaPinterestSquare size={28} color="red"/>
        </div>
      </div>
      </div>
   
    </footer>
  )
}

export default Footer