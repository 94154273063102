import { useForm } from 'react-hook-form'
import { Card } from '../components/ui/card'
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { Button } from "../components/ui/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../components/ui/form"
import { Input } from "../components/ui/input"

const formSchema = z.object({
  fullName: z.string().min(2, { message: 'Full name must be at least 2 characters long' }).max(50, { message: 'Full name must not exceed 50 characters' }),
  email: z.string().min(2, { message: 'Email must be at least 2 characters long' }).max(50, { message: 'Email must not exceed 50 characters' }),
  mobile: z.string().min(10, { message: 'Mobile number must be exactly 10 characters long' }).max(10, { message: 'Mobile number must be exactly 10 characters long' }),
  message: z.string().min(0, { message: 'Message must be at least 0 characters long' }).max(200, { message: 'Message must not exceed 200 characters' }),
});

const ContactUs = () => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      fullName: "", email: "", message: "", mobile: ""
    },
  })

  function onSubmit(values: z.infer<typeof formSchema>) {
    console.log(values)
  }
  return (
    <div className='wrapper grid md:grid-cols-2 grid-cols-1 gap-6'>
      <div className=' w-full flex flex-col  '>
        <img src="/assets/conatct.png" alt="contact" className='w-full rounded-lg max-md:hidden'/>
        <div className='space-y-3 my-6'>
          <p className='text-lg font-semibold'>Contact Details:</p>
          <div className="flex gap-3 justify-between">
            <p><span className='font-medium'>Mobile:</span>9999999999</p>
            <p><span className='font-medium'>Email:</span>User@gmail.com</p>
          </div>
          <p><span className='font-medium'>Address:</span> 22/11, Mysore, Banglore.</p>
        </div>
        </div>
      <div>
        <p className='text-xl font-bold'>CONTACT US</p>
        <div className='max-w-xl flex justify-center my-4'>
          <Card className='w-full  p-3'>
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
                <FormField
                  control={form.control}
                  name="fullName"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Full Name*</FormLabel>
                      <FormControl>
                        <Input placeholder="Name" {...field} className='input-field' />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Eamil*</FormLabel>
                      <FormControl>
                        <Input placeholder="Email" {...field} className='input-field' />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="mobile"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Mobile*</FormLabel>
                      <FormControl>
                        <Input placeholder="Mobile" {...field} className='input-field' />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="message"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Message</FormLabel>
                      <FormControl>
                        <Input placeholder="Message" {...field} className='input-field' />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Button type="submit" className='headermenu w-[100px]'>Submit</Button>
              </form>
            </Form>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default ContactUs