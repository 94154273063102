

export const subHeaderLinks = [
  {
    label: 'HOME',
    route: '/',
  },
  {
    label: 'SERVICES',
    route: '/services',
    subroutes:[{
      label: 'HR Consulting',
      route: '/hrconsulting',
    },
    {
      label: 'HR Outsourcing',
      route: '/hroutsourcing',
    },
    {
      label: 'Recruiting Services',
      route: '/recruitingservices',
    },
  ]
  },
  {
    label: 'ABOUTUS',
    route: '/aboutUs',
  },
    {
      label: 'CONTACTUS',
      route: '/contactus',
    },
 
]
export const MobileLinks = [
  {
    label: 'HOME',
    route: '/',
  },
  {
    label: 'HR CONSULTING',
    route: '/hrconsulting',
  },
  {
    label: 'HR OUTSOURCING',
    route: '/hroutsourcing',
  },
  {
    label: 'RECRUITING SERVICES',
    route: '/recruitingservices',
  },
{
      label: 'ABOUTUS',
      route: '/aboutUs',
    },
    {
      label: 'CONTACTUS',
      route: '/cONTACTUS',
    },

]

export const HomeServices=[
{id:1,  
  heading:"Moving skilled professionals to the UK, Europe, Canada & Dubai is what we do best",
  text: `TOM Universal is a registered corporation with the Indian government; TOM Universal Pvt Ltd offers a variety of immigration services to help both corporate and individual clients with their visa-related problems. We help small and medium-sized businesses find Indian talent and are experts in processing visas for skilled workers. Our clientele come from the IT sector.\n We also work with individual clients and offer in-depth support for all types of immigration. We can assist you in realizing your immigration aspirations, whether you are thinking about travelling briefly, moving abroad for school, or joining a relative. We will take the time to learn about your needs since we understand how essential it is to seek reliable guidance and specialized services when it comes to immigration, and we will take the time to understand your needs in order to provide the best possible solution at a competitive price.`,
  img:"/assets/visa.png"
},
{id:2,
  heading:"Avoid being struck. Choose the right visa for your needs.",
  text:"At TOM Universal, we take pleasure in assisting IT and healthcare professionals who wish to immigrate to the UK, Europe, or Canada with the goal of settling down there permanently. We take pride in providing the most individualised immigration service possible, adjusting our recommendations to your needs and making sure that your case has the best possible chance of being approved. We process applications quickly and effectively, making sure that our clients are informed at every step.\n We are committed and have in-depth knowledge of the requirements for immigration to the UK, Europe, and Canada. We recognise how crucial it is to seek reliable assistance and qualified services when it comes to immigration, so we will take the time to learn about your needs and offer the most suitable solution at a reasonable cost.\n To guarantee that the application is granted, it is critical that the appropriate documentation be here in place.",
  img:"/assets/visa_appr.png"
},
{id:3,
  heading:"The UK Health Care Visa as Care Assistant",
  text:"The UK Health Care Visa is a type of visa introduced by the UK government to support the recruitment of skilled healthcare professionals from outside the European Union (EU) and European Economic Area (EEA). The Health Care Visa is specifically designed to help non-UK citizens with qualifications in healthcare to work in the UK National Health Service (NHS) or the UK private healthcare sector.\nAs a Care Assistant, the UK Health Care Visa would allow you to work in the UK in a care home or residential setting, providing support to patients who require assistance with day-to-day activities such as personal care, mobility and social engagement. You would be required to have a relevant qualification in health and social care and a minimum of six months' experience working in a care setting.",
  img:"/assets/health_care.png"
},
{id:4,
  heading:"IT Professional",
  text:"The IT Professional visa is a type of visa designed by the UK government for highly skilled IT professionals who want to work in the UK. This visa category is part of the UK's Points-Based System, which assesses applicants based on their skills, experience, and qualifications.\nTo be eligible for an IT Professional visa, applicants must have a job offer from a UK employer that is on the list of eligible sponsors. They must also have a Certificate of Sponsorship from their employer and score enough points on the Points-Based System.\nThe Points-Based System takes into account several factors, including the applicant's qualifications, previous earnings, and English language proficiency. Applicants must score a minimum of 70 points to be eligible for the IT Professional visa.",
  img:"/assets/it_prof.png"
},
{id:5,
  heading:"Recruitment",
  text:"The leading destination for professionals seeking their perfect career in the United Kingdom. We understand that finding the right job can be a challenging and time-consuming process. That's why we're here to simplify it for you.\nAt Tom Universal, we pride ourselves on our extensive network of trusted employers spanning various industries. Whether you're an experienced professional looking for a senior executive role or a fresh graduate searching for your first opportunity, we have connections that match your aspirations.\nOur team of experienced recruiters and advanced algorithms work tirelessly to ensure that we deliver personalized job matches tailored to your unique skills, interests, and career goals. We believe that finding the right fit is crucial for long-term success and job satisfaction.\nWith our deep knowledge of the UK job market and industry insights, we stay ahead of the latest trends and opportunities. This expertise allows us to provide you with valuable guidance and support throughout your job search journey. We're committed to helping you navigate the competitive landscape and make informed career decisions.",
  img:"/assets/rec.jpg"
},
]

export const visaServices=[
{id:1,  
  heading:"UK Skilled worker Visa",
  text:"The Skilled Worker visa allows you to come and stay in the UK to do an eligible job with an approved employer. This visa has replaced the Tier 2 (General) work visa.",
  img:"/assets/country/uk.png"
},
{id:2,
  heading:"UK Start-Up visa",
  text:"Allows you to work on your business idea and in parallel, also allows you to do full-time employment.\nThis visa is for 2 years and allows you to switch to other valid visa categories.\nAt the end of the 2-year you can switch to other qualified visa categories.",
  img:"/assets/country/uk.png"
},
{id:3,
  heading:"Australia PR visa",
  text:"Australia is an extremely popular destination for skilled professionals and tradespeople wishing to start a new life in another country. Australia & economy has remained strong throughout recent years, leading to a high demand for skilled migrants.",
  img:"/assets/country/aus.png"
},
{id:4,
  heading:"Poland Work Permit",
  text:"Poland immigration under the category of work is made available to non-EU citizens through work permits with a duration for which the work permit granted is constant.",
  img:"/assets/country/poland.png"
},
{id:5,
  heading:"United Arab Emirates",
  text:"Seven emirates, 1 destination - Visit the United Arab Emirates & discover a land rich in history & tradition. Take a tour to the desert & explore the wild side of Arabia. Then, relax your mind, body and soul at the exclusive resorts. Find your paradise in the UAE.",
  img:"/assets/country/uae.png"
},
{id:6,
  heading:"Canada PR visa",
  text:"Canada is one of the world's top immigration destinations, with over 250,000 new immigrants arriving in Canada every year to take advantage of the country & strong economy and progressive immigration system.",
  img:"/assets/country/canda.png"
},
]

export const ChooseUS=[
{id:1,  
  heading:"Expertise:",
  text:" Our team of immigration providers has years of experience and expertise in helping clients obtain various types of visas, including VISA Switch to UK, General Skilled work visa Tier 2 VISA, Immigrant visas for skilled professionals. We stay up-to-date with the latest immigration laws and regulations to ensure that our clients receive the most accurate and reliable advice.",
  
},
{id:2,
  heading:"Personalized service:",
  text:" We understand that every client's situation is unique, so we provide personalized service tailored to each individual's needs. We take the time to understand your goals and requirements, and work with you to develop a customized immigration strategy that suits your needs."
},
{id:3,
  heading:"Smooth and hassle-free process:",
  text:" We take care of all the paperwork and procedures involved in the immigration process, ensuring a smooth and hassle-free experience for our clients. We guide you through the entire process, from initial consultation to visa application and beyond."
},
{id:4,
  heading:"Affordable pricing:",
  text:" We offer competitive pricing for our services, without compromising on the quality of our work. We believe that everyone should have access to professional immigration services, so we strive to keep our fees reasonable and transparent."
},
{id:5,
  heading:"Responsive communication:",
  text:" We understand the importance of timely communication and keeping our clients informed throughout the process. We are always available to answer your questions and provide updates on your case, ensuring that you are kept informed every step of the way."
},
]


export const quickLinks=["Wedding",
  "Party",
  "Festival",
  "Vacation Tour"]

export const userPolicy = [
  { name: "Privacy Policy", route: "/privacy-policy" },
  { name: "Terms & Conditions", route: "/terms-and-conditions" },
];

export const letUsHelpYou = [
  { path: "/stores", name: "Stores" },
  { path: "/help-faq", name: "Help/FAQ" },
  { path: "/enquiry", name: "Franchise Enquiry" },
  { path: "/about-us", name: "About us" }
];








export const privacyConstants = [
  {
    title: "3.Data Security",
    items: 
      "We take reasonable measures to protect your personal information from loss, theft, misuse, unauthorized access, disclosure, alteration, and destruction. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure."
    
  },
  {
    title: "4.Your Rights",
    items: 
      "You have the right to access the personal information we hold about you, correct inaccuracies, and request the deletion of your personal information. To exercise these rights, please contact us at manager@chennapatnampattucheeralaangadi.com"
    
  },
  {
    title: "5.Changes to This Policy",
    items: 
      "We may update this privacy policy from time to time. The updated version will be indicated by an updated 'Last updated' date. We encourage you to review this policy periodically."
  
  },
  {
    title: "6.Contact Us",
    items: 
      "For any questions, concerns, or requests regarding your privacy, please contact us at manager@chennapatnampattucheeralaangadi.com"
    
  }
];


