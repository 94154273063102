import { FaCircleArrowRight } from 'react-icons/fa6'
import { Card } from '../components/ui/card'
import React from 'react'

const RecruitingServices = () => {
  return (
    <div className=''>
    <img src="/assets/rc.jpg" alt="hr" className="w-full "/>
    <div className="wrapper">
    <div className='grid md:grid-cols-2 grid-cols-1 gap-10 my-10'>
    <img src="/assets/rcs1.png" alt="256" />
   
      <div className='mx-4'>
        <Card className="p-2 my-4 space-y-3"><p className='font-semibold text-lg '>Job Posting and Advertising:</p>
        <p className='flex gap-1 items-center'><FaCircleArrowRight />Creating and distributing job advertisements across various platforms.</p>
         <p className='flex gap-1 items-center'><FaCircleArrowRight />Utilizing online job boards, social media, and industry-specific sites.</p> 
          <p className='flex gap-1 items-center'><FaCircleArrowRight />Developing compelling job descriptions to attract top talent.</p>
         <p className='flex gap-1 items-center'><FaCircleArrowRight />Managing responses and tracking applications.</p>
         </Card>
        <Card className="p-2 my-4  space-y-3"><p className='font-semibold text-lg'>Candidate Sourcing:</p>
        <p className='flex gap-1 items-center'><FaCircleArrowRight />Identifying and attracting candidates through various channels.</p>
         <p className='flex gap-1 items-center'><FaCircleArrowRight />Leveraging databases, professional networks, and recruitment agencies.</p> 
          <p className='flex gap-1 items-center'><FaCircleArrowRight />Conducting proactive outreach to passive candidates.</p>
         <p className='flex gap-1 items-center'><FaCircleArrowRight /> Utilizing technology and tools to find qualified candidates.</p>
         </Card>
     
       </div>
    </div>
    <div className='grid md:grid-cols-2 grid-cols-1 gap-10 my-10'>
    <img src="/assets/rcs2.jpg" alt="256"  className="w-full rounded-lg max-md:block hidden"/>
      <div className='mx-4'>
      <Card className="p-2 my-4  space-y-3"><p className='font-semibold text-lg'>Screening and Selection:</p>
        <p className='flex gap-1 items-center'><FaCircleArrowRight />Reviewing resumes and applications to shortlist candidates.</p>
         <p className='flex gap-1 items-center'><FaCircleArrowRight />Conducting initial phone screens to assess candidate suitability.</p> 
          <p className='flex gap-1 items-center'><FaCircleArrowRight />Administering skills assessments and aptitude tests.</p>
         <p className='flex gap-1 items-center'><FaCircleArrowRight />Coordinating interviews with hiring managers and candidates.</p>
         </Card>
        <Card className="p-2 my-4 space-y-3 "><p className='font-semibold text-lg space-y-4'>Onboarding and Integration:</p>
        <p className='flex gap-1 items-center'><FaCircleArrowRight />Facilitating the smooth transition of new hires into the company.</p>
         <p className='flex gap-1 items-center'><FaCircleArrowRight />Coordinating onboarding activities and paperwork.</p> 
          <p className='flex gap-1 items-center'><FaCircleArrowRight />Providing orientation sessions and training materials.</p>
         <p className='flex gap-1 items-center'><FaCircleArrowRight />Assisting with setting up workstations and access to systems.</p>
         </Card>
       </div>
       <img src="/assets/rcs2.jpg" alt="256"  className="w-full rounded-lg md:block hidden"/>
    </div>
    </div>
  
  </div>
  )
}

export default RecruitingServices