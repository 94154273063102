import React from 'react'

const AboutUs = () => {
  return (
    <div className='wrapper min-h-[600px] md:grid-cols-2 grid grid-cols-1 gap-8 '>
      <img src="/assets/au.png" alt="about"  className='w-full rounded-lg bg-gray-500'/>
      <div className='space-y-3'>
        <p className='font-semibold'>ABOUTUS:</p>
        <p>Welcome to TOM Universal, where we specialize in providing comprehensive HR solutions tailored to meet the unique needs of your organization. Our mission is to empower businesses by enhancing their human resources capabilities, fostering a productive and engaged workforce, and ensuring compliance with industry standards and regulations.</p>
        <p>Founded in 2000, TOM Universal has grown into a trusted partner for businesses across various industries. Our team of experienced HR professionals brings a wealth of knowledge and expertise, ensuring that our clients receive the highest quality service and support.</p>
    </div>
    </div>
  )
}

export default AboutUs