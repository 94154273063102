import HomeSub from "../components/shared/Home/HomeSub"
import { Fade,Zoom  } from "react-awesome-reveal";
import { PiAirplaneTakeoffLight } from "react-icons/pi";
import { SiWorldhealthorganization } from "react-icons/si";
import { BsBriefcase  } from "react-icons/bs";
import { RiFlightTakeoffLine, RiUserSearchLine } from "react-icons/ri";
import { Card } from "../components/ui/card";

const Home = () => {
  return (
    <>
    <div className="relative">
   <div className="flex justify-end ">
    <img src="/assets/main_banner.png" alt="homeimg" />
    <p className="absolute top-[50%] left-10  md:left-48 font-semibold md:text-4xl text-2xl max-w-[400px]">Migrating to UK with Family & Job is easy Now !</p>
   </div>
   </div>
   <div className=" wrapper grid md:grid-cols-4 gap-2 max-sm:grid-cols-1 md:gap-10 grid-cols-2 text-lg  text-black justify-center  text-md font-semibold ">
   
<Card className="flex flex-col gap-4 p-4  bg-gray-100 justify-center items-center">
  <PiAirplaneTakeoffLight   size={40}/>
  <p className="text-blue-500">VISA Switch to UK</p>
  </Card>
<Card className="flex flex-col gap-4 p-4  bg-gray-100 justify-center items-center">
  <SiWorldhealthorganization   size={40}/>
  <p className="text-blue-500">Health care visa</p>
  </Card>
<Card className="flex flex-col gap-4 p-4  bg-gray-100 justify-center items-center">
  <BsBriefcase   size={40}/>
  <p className="text-blue-500">General Skilled work visa Tier 2 VISA</p>
  </Card>
<Card className="flex flex-col gap-4 p-4  bg-gray-100 justify-center items-center">
  <RiUserSearchLine    size={32}/>
  <p className="text-blue-500">Recruitment</p>
  </Card>

   </div>
   <div>
    <HomeSub/>
   </div>
    </>
  )
}


export default Home


